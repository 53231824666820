import React, { useState } from 'react'
import { Link } from 'gatsby'
import { Fade, Slide } from 'react-reveal'

import Button from './Button'
import dribbble from '../assets/icons/dribbble-dark.svg'
import instagram from '../assets/icons/instagram-dark.svg'
import linkedin from '../assets/icons/linkedin-dark.svg'

interface NavbarProps {
  bgColor: string
  textColor: string
}

export default function Navbar({ textColor }: NavbarProps) {
  const [state, setState] = useState(false)

  const handleClick = () => {
    setState(!state)
  }

  return (
    <nav
      className={`${textColor} sticky top-0 z-10 flex items-center justify-between bg-blend-hard-light backdrop-blur-md`}
    >
      <div className="navbar py-2 px-6 md:py-4 md:px-6">
        <div className="nav-container">
          <Fade delay={900}>
            <h3 className="text-2xl font-semibold">
              <Link className="font-serif" to="/">
                ksatra.
              </Link>
            </h3>
          </Fade>

          <Fade delay={1200}>
            <div className="hidden justify-center gap-12 md:flex">
              <Link
                className="underline-animation text-gray-200 hover:text-white"
                to="/design"
              >
                Design
              </Link>
              <Link
                className="underline-animation text-gray-200 hover:text-white"
                to="/code"
              >
                Code
              </Link>
              <Link
                className="underline-animation text-gray-200 hover:text-white"
                to="/play"
              >
                Play
              </Link>
              <Link
                className="underline-animation text-gray-200 hover:text-white"
                to="/a-propos"
              >
                À propos
              </Link>
            </div>
          </Fade>
          <Fade delay={1300}>
            <Link
              className="hidden text-gray-200 hover:text-white md:flex"
              to="/contact"
            >
              <Button title="Contact" />
            </Link>
          </Fade>

          <label htmlFor="menu-burger" className="sm:hidden"></label>

          <input
            className="checkbox sm:hidden"
            type="checkbox"
            onClick={handleClick}
            name="menu-burger"
            id="menu-burger"
          />

          <div className="hamburger-lines sm:hidden">
            <span
              className={!state ? 'line line1' : 'line line1 !bg-gray'}
            ></span>
            <span
              className={!state ? 'line line2' : 'line line2 !bg-gray'}
            ></span>
            <span
              className={!state ? 'line line3' : 'line line3 !bg-gray'}
            ></span>
          </div>

          {state && (
            <Slide duration={500} right>
              <div className="menu-items">
                <div className="gap-2">
                  <div className="px-12">
                    <div className="flex flex-col justify-between">
                      <div className="mb-8 flex flex-col">
                        <div className="flex flex-col justify-end gap-4 font-serif text-3xl text-gray">
                          <Link to="/">Accueil.</Link>
                          <Link to="/design">Design.</Link>
                          <Link to="/code">Code.</Link>
                          <Link to="/play">Play.</Link>
                          <Link to="/a-propos">À propos.</Link>
                          <Link to="/contact">Contact.</Link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div>
                  <div className="px-12">
                    <p className="text-sm font-normal text-gray-300">
                      Contactez-moi
                    </p>
                    <p className="font-serif text-2xl text-gray">
                      contact@ksatra.fr
                    </p>
                  </div>

                  <div className="mx-12 mt-8 mb-8 flex gap-4">
                    <Link
                      target="blank"
                      to="https://www.linkedin.com/in/ksatra/"
                    >
                      <img
                        className="pb-4"
                        src={linkedin}
                        alt="logo linkedin"
                      ></img>
                    </Link>
                    <Link
                      target="blank"
                      to="https://www.instagram.com/ksatra_s/"
                    >
                      <img src={instagram} alt="logo instagram"></img>
                    </Link>
                    <Link target="blank" to="https://dribbble.com/ksatra">
                      <img src={dribbble} alt="logo dribbble"></img>
                    </Link>
                  </div>

                  <div className="px-12 text-sm text-gray-300">
                    <p>© 2023 Ksatra. Tous droits réservés</p>
                  </div>
                </div>
              </div>
            </Slide>
          )}
        </div>
      </div>
    </nav>
  )
}
