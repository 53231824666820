import React, { FC } from "react";
interface Props {
  title: string;
}

const Button: FC<Props> = ({ title }) => {
  return (
    <button
      className={`button__wrapper button__target rounded-lg py-3 px-6 pt-4 font-semibold`}
    >
      {title}
    </button>
  )
}

export default Button;
